import { ReactNode } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export enum ModalSize {
    MSMALL = '320px',
    SMALL = '480px',
    MEDIUM = '672px',
    LARGE = '840px',
    HUGE = '1200px',
    MHUGE = '1360px',
    AUTO = '96%',
}
interface ModalContainerProps {
    size: ModalSize;
    title: string;
    description?: string;
    children: ReactNode;
    open: boolean;
    handleClose: (a: boolean) => void;
    height?: string;
    titlesize ?: string;
    titleweight ?: number;
    minHeight?: any;
    maxHeight?: any;
}

const ModalContainer = ({ size, title, description, children, open, handleClose, height,titlesize,titleweight,minHeight,maxHeight = "90%"
    // ,overflowY,widthH ="100%"
}: ModalContainerProps) => {
          /* eslint-disable */

    const modalStye : any = {
        width: size,
        height: height,
        minHeight: minHeight,
        maxHeight: maxHeight,
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 2.5,
        display: 'flex',
        flexDirection: 'column',
        };
    const contentStyle  : any = {
        overflowY: 'auto',
        overflowX: 'auto',
        flexGrow: 1,     
    };
    
    return (
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <Box sx={modalStye}>
                    <Box id="modal-title" display={'flex'} justifyContent={'space-between'}>
                        <Stack direction={'column'} spacing={1} marginBottom={1} alignItems="start">
                            <Typography color={'text.primary'} fontSize={titlesize} fontWeight={titleweight}>
                                {title}
                            </Typography>
                            {description !== '' && (
                                <Typography color={'text.primary'} variant="body1">
                                    {description}
                                </Typography>
                            )}
                        </Stack>
                        <Stack style={{ alignItems: 'start' }}>
                            <IconButton
                                onClick={() => {
                                    handleClose(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Box>
                    <Box id="modal-description" sx={contentStyle}>
                        {children}   
                    </Box>
                </Box>
            </>
        </Modal>
    );
};

export default ModalContainer;
