// @mui
import styled from 'styled-components';
// import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Button, AppBar, Toolbar, Container, Typography, IconButton, Menu, MenuItem } from '@mui/material';
// hooks

import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER } from 'src/config-global';
import LanguageIcon from '@mui/icons-material/Language';

// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import SettingsDrawer from 'src/components/settings/drawer';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
//
import { BsPerson, BsCart, BsFillCartFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavMobile, NavDesktop, navConfig } from '../nav';
import Searchbar from '../../components/Searchbar';
import HeaderShadow from '../../components/HeaderShadow';
// import { SigninByEmailDto } from "../../../generated";
import { publicApi, privateApi } from '../../../react-query';

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};
// const fetcherSignin = (params: SigninByEmailDto) => publicApi.vod.usersControllerStudentSignin(params);
const fetcherMyInfo = () => privateApi.vod.wpMemberControllerWpReadMyInfo();

export default function Header({ headerOnDark }: Props) {
  /* eslint-disable */
  const {t,i18n} = useTranslation()
  const [CheckLogin, setCheckLogin] = useState(t("login"));
  const [MyName, setMyName] = useState('NotLoggedin');

  const theme = useTheme();
  const navigate = useNavigate();

  const isMdUp = useResponsive('up', 'lg');
  const isXsUp = useResponsive('down', 'sm');

  const isOffset = useOffSetTop();
  const [HomeVisble, setHomeVisble] = useState(true);
  const [WorkVisble, setWorkVisble] = useState(true);
  const [CategoryVisible, setCategoryVisible] = useState(true);
  const [PostVisible, setPostVisible] = useState(true);
  const [CourseVisible, setCourseVisible] = useState(true);
  const [TeacherVisible, setTeacherVisible] = useState(true);
  const [DownVisible, setDownVisible] = useState(false);
  const [ReviewVisible, setReiviewVisible] = useState(true);
  const [HomeHeader, setHomeHeader] = useState('홈');
  const [WorkHeader, setWorkHeader] = useState('커리큘럼');
  const [CategoryHeader, setCategoryHeader] = useState('강의 카테고리');
  const [PostHeader, setPostHeader] = useState('커뮤니티');
  const [CourseHeader, setCourseHeader] = useState('강의');
  const [TeacherHeader, setTeacherHeader] = useState('강사 소개');
  const [DownHeader, setDownHeader] = useState('');
  const [ReviewHeader, setReiviewHeader] = useState('리뷰');
  const [anchorLang, setAnchorLang] = useState<null | HTMLElement>(null);
  const openlang = Boolean(anchorLang);
  const [Lang, setLang] = useState(localStorage.getItem('i18nextLng') === null ? 'ko' : localStorage.getItem('i18nextLng'));
  const changeLanguage = (lng : any) => {
      // localStorage.setItem('lang', lng);
      i18n.changeLanguage(lng);
  };
  useEffect(() => {
    // console.log(window.location)
    // setHomeVisble(sessionStorage.getItem("homeVisible") === "1" ? true :false)
    // setWorkVisble(sessionStorage.getItem("workVisible") === "1" ? true :false)
    // setCategoryVisible(sessionStorage.getItem("categoryVisible")=== "1" ? true :false)
    // setPostVisible(sessionStorage.getItem("postVisible")=== "1" ? true :false)
    // setCourseVisible(sessionStorage.getItem("courseVisible")=== "1" ? true :false)
    // setTeacherVisible(sessionStorage.getItem("teacherVisible")=== "1" ? true :false)
    // setDownVisible(sessionStorage.getItem("downVisible")=== "1" ? true :false)
    // setReiviewVisible(sessionStorage.getItem("reiviewVisible")=== "1" ? true :false)
    // setHomeHeader(sessionStorage.getItem("homeHeader") as string)
    // setWorkHeader(sessionStorage.getItem("workHeader") as string)
    // setCategoryHeader(sessionStorage.getItem("categoryHeader") as string)
    // setPostHeader(sessionStorage.getItem("postHeader") as string)
    // setCourseHeader(sessionStorage.getItem("courseHeader") as string)
    // setTeacherHeader(sessionStorage.getItem("teacherHeader") as string)
    // setDownHeader(sessionStorage.getItem("downHeader") as string)
    // setReiviewHeader(sessionStorage.getItem("reviewHeader") as string)
    if (window.sessionStorage.getItem('x-at') !== null) {
      setCheckLogin(t("logout"));
    } else setCheckLogin(t("login"));
  }, []);
  useEffect(() => {
    if (CheckLogin === '로그아웃' || CheckLogin === 'Log out') {
      mutateMyInfo();
    }
  }, [CheckLogin]);
  const handleCloseLang = () => {
    setAnchorLang(null);
};
const onClickLng = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorLang(event.currentTarget);
};
  // const { isLoading: loadingSignin, mutate: mutateSignin } = useMutation((params: SigninForTeacherDto) => fetcherSignin(params), {
  //   onSuccess: (e) => {
  //     if (e.data.header.isSuccess) {
  //       console.log(e)
  //       // const accessToken = e.data.body.data.token;
  //       // authStore.onSignin(accessToken);
  //       // window.sessionStorage.setItem("x-at", accessToken);

  //     } else {
  //       // message.error(e.data.header.resultMessage);
  //     }
  //   },
  //   onError: (e) => {
  //     console.log("login onError : ", e);
  //   },
  // });
  const { mutate: mutateMyInfo } = useMutation(() => fetcherMyInfo(), {
    onSuccess: (e: any) => {
      if (e.data.header.isSuccess) {
        console.log(e);
        setMyName(e.data.body.data[0]?.memberName);
        // const accessToken = e.data.body.data.token;
        // authStore.onSignin(accessToken);
        // window.sessionStorage.setItem("x-at", accessToken);
      } else {
        if (e.data.header.resultCode == 403) {
          navigate('/auth/duplicated');
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log('login onError : ', e);
    },
  });

  return (
    <AppBar color="transparent" sx={{ boxShadow: 'none', borderBottom: '1px solid #f7f7f7' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP - 16,
            whiteSpace: 'nowrap',
            // md: 50,
          },
          // background : "white",
          ...bgBlur({ color: theme.palette.background.default }),
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', maxWidth: '1600px !important' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo />
            {/* <Link href="https://zone-docs.vercel.app/changelog" target="_blank" rel="noopener">
              <Label
                color="info"
                sx={{
                  ml: 0.5,
                  px: 0.5,
                  top: -14,
                  left: 60,
                  height: 20,
                  fontSize: 11,
                  cursor: 'pointer',
                  position: 'absolute',
                }}
              >
                v2.0
              </Label>
            </Link> */}
          </Box>

          {isMdUp && window.location.pathname === '/e-learning/landing' && (
            <div
              style={{
                display: 'flex',
                gap: 46,
                justifyContent: 'flex-start',
                width: '100%',
                marginLeft: 70,
              }}
            >
              {HomeVisble && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('home')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {t("home")}
                </HeaderMenu>
              )}

              {TeacherVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('teacher')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                                    {t("teacherinfo")}

                </HeaderMenu>
              )}

              {WorkVisble && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('work')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                    {t("curriculum")}
                </HeaderMenu>
              )}

              {/* <HeaderMenu 
                role="presentation"
                onClick={(e)=>{
                  const testdiv : any = document.getElementById("work_with")?.getBoundingClientRect().top
                  const top2 = window.pageYOffset + testdiv
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                  });
                }}>Work With</HeaderMenu> */}

              {/* {CategoryVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('category')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {CategoryHeader}
                </HeaderMenu>
              )}
                */}

              {CourseVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('course')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                   {t("classroom")}
                </HeaderMenu>
              )}

              {/* 
              {ReviewVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('review')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {ReviewHeader}
                </HeaderMenu>
              )}

              {PostVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('post')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {PostHeader}
                </HeaderMenu>
              )}

              {DownVisible && (
                <HeaderMenu
                  role="presentation"
                  onClick={(e) => {
                    const testdiv: any = document
                      .getElementById('download')
                      ?.getBoundingClientRect().top;
                    const top2 = window.pageYOffset + testdiv;
                    window.scrollTo({
                      top: top2,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {DownHeader}
                </HeaderMenu>
              )} */}
            </div>
          )}

          <Stack
            spacing={1.5}
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
             <IconButton
                            onClick={onClickLng}
                            color="warning"
                            sx={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '8px',
                                '&:hover': {
                                    bgcolor: 'warning.light'
                                }
                            }}
                        >
                            <LanguageIcon sx={{ width: '24px', height: '24px', color: '#707070' }} />
                        </IconButton>
            <Button
              variant="contained"
              color="error"
              sx={{
                ml: 2,
                minWidth: { xs: 40, sm: 75 },
                borderRadius: '20px',
                height: 32,
                '&:hover': {
                  boxShadow: 'none',
                },
              }}
              onClick={() => {
                navigate('/e-learning/price');
              }}
            >
              {t("price")}
              {/* 수강료 */}
            </Button>

            {!isXsUp && (
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={
                  CheckLogin === '로그아웃'
                    ? { fontSize: '10px', whiteSpace: 'nowrap' }
                    : { display: 'none' }
                }
              >
                {window.location.pathname === '/e-commerce/account/wishlist' ? (
                  <BsFillCartFill
                    size={18}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                      navigate('/e-commerce/account/wishlist');
                    }}
                  />
                ) : (
                  <BsCart
                    size={18}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                      navigate('/e-commerce/account/wishlist');
                    }}
                  />
                )}

                {/* <SettingsDrawer /> */}
              </Stack>
            )}

            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ fontSize: '10px', whiteSpace: 'nowrap' }}
            >
              {/* <Searchbar /> */}
              {/* <BsPerson size={20}
              style={{marginRight:10}}
              onClick={()=>{
                if(CheckLogin === "Login"){
                  sessionStorage.setItem("Check", "true")
                  navigate("/auth/login-cover")
                }
                else {
                  
                  navigate("/e-commerce/account/mylist")

                }
                // mutateSignin({
                //   teacherId :"lydian",
                //   password : "1234"
                // })
          
              }}
              />  */}

              {/* <Link  component={RouterLink} to={paths.eCommerce.account.mylist} color="inherit">
              <Typography sx={{fontSize : {md : 14 ,xs : 12} ,mt: {md : 0.5 },fontWeight : 800} }>
              {MyName === "" ? "" : `내 강의`} 
              </Typography>
              </Link> */}
              {!isXsUp ? (
                <Link component={RouterLink} to={paths.eCommerce.account.personal} color="inherit">
                  <Typography
                    sx={{ fontSize: { md: 14, xs: 12 }, mt: { md: 0.5 }, fontWeight: 800 }}
                  >
                    {/* {MyName === "" ? "" : `${MyName} 님`}  */}
                    {MyName === 'NotLoggedin' ? '' : `${MyName}님`}
                  </Typography>
                </Link>
              ) : (
                ''
              )}

              <Button
                variant="soft"
                color="warning"
                sx={{
                  display: MyName === 'NotLoggedin' ? 'none' : 'inline-block',
                  minWidth: { xs: 40, sm: 90 },
                }}
                onClick={() => {
                  navigate('/e-commerce/account/mylist');
                }}
              >
                {/* {MyName} */}
                {t("classroom")}
              </Button>
            </Stack>

            {isMdUp && (
              <Button
                variant="contained"
                color="inherit"
                sx={{ display: 'inline-block', minWidth: 90 }}
                onClick={() => {
                  if (CheckLogin === '로그인' || CheckLogin === 'Log in') {
                    // console.log(window.location.pathname)
                    sessionStorage.setItem('history', window.location.pathname);
                    navigate('/auth/login-cover');
                  } else {
                    window.sessionStorage.removeItem('x-at');
                    window.sessionStorage.removeItem('MyIdx');
                    navigate('/e-learning/landing');
                    window.location.reload();

                    // setCheckLogin("Login")
                  }
                  // mutateSignin({
                  //   teacherId :"lydian",
                  //   password : "1234"
                  // })
                }}
              >
                {CheckLogin === "로그인" ||CheckLogin === "Log in"  ?  t("login") : t("logout") }
                {/* {window.sessionStorage.getItem("x-at") !== null? "Logout" : "Login"} */}
              </Button>
            )}
            {isMdUp && (
              <Button
                variant="outlined"
                color="inherit"
                sx={CheckLogin !== '로그인' && CheckLogin !== 'Log in' ? { display: 'none' } : { whiteSpace: 'nowrap' }}
                onClick={() => {
                  navigate('/auth/register-cover');
                }}
              >
                  {t("register")}
              </Button>
            )}
          </Stack>

          {!isMdUp && <NavMobile data={MyName} />}
        </Container>
      </Toolbar>
      <Menu
                anchorEl={anchorLang}
                id="account-menu"
                open={openlang}
                onClose={handleCloseLang}
                onClick={handleCloseLang}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: '196px',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    sx={{ width: '100%', height: '48px', background: Lang === 'en' ? 'lightgray' : 'white' }}
                    onClick={() => {
                        setLang('en');
                        changeLanguage('en');
                    }}
                >
                    English
                </MenuItem>
                <MenuItem
                    sx={{ width: '100%', height: '48px', background: Lang === 'ko' ? 'lightgray' : 'white' }}
                    onClick={() => {
                        setLang('ko');

                        changeLanguage('ko');
                    }}
                >
                    Korean
                </MenuItem>
            </Menu>
      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}

const HeaderMenu = styled.div`
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: #006c9c;
    // background-color: white;
    // opacity: 0.9;
  }
`;
