// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import TranslationEn from './utils/locales/en/translation.json';
import TranslationKo from './utils/locales/ko/translation.json';

const resource = {
    en: {
        translations: TranslationEn
    },
    ko: {
        translations: TranslationKo
    }
};
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const easternTimeZones = ['Asia/Seoul'];
const AlreadySelectedLanguage = localStorage.getItem("i18nextLng")
i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resource,
        // eslint-disable-next-line no-nested-ternary
        lng: AlreadySelectedLanguage !== null ? AlreadySelectedLanguage : easternTimeZones.includes(userTimeZone) ? 'ko' : 'en',
        // eslint-disable-next-line no-nested-ternary
        fallbackLng: AlreadySelectedLanguage !== null ? AlreadySelectedLanguage :  easternTimeZones.includes(userTimeZone) ? 'ko' : 'en',
        debug: true,
        defaultNS: 'translations',
        ns: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
        // detection: {
        //   order: ['queryString', 'cookie','navigator'],
        //   cache: ['cookie']
        // },
        // backend: {
        //   // loadPath: 'locales/{{lng}}/{{ns}}.json'
        //   loadPath: '/locales/{{lng}}/{{ns}}.json'
        // },
        // react: {
        //   useSuspense: false
        // }
    });
console.log('i18n.js 파일이 제대로 불러와졌습니다.');

export default i18n;
