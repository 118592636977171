import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton, Button, Stack, Typography } from '@mui/material';
// config
import { NAV } from 'src/config-global';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useTranslation } from 'react-i18next';

//
import { NavProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavMobile({ data }: any) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [CheckLogin, setCheckLogin] = useState(t("login"));
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('x-at') !== null) {
      setCheckLogin(t("logout"));
    } else setCheckLogin(t("login"));
  }, []);
  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1, color: 'inherit' }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />
          <Typography
            sx={{ mx: 2.5, my: 3, fontSize: { md: 14, xs: 12 }, mt: { md: 0.5 }, fontWeight: 700 }}
          >
            {/* {MyName === "" ? "" : `${MyName} 님`}  */}
            {data === 'NotLoggedin' ? '' : `${data} 님`}
          </Typography>
          <List
            component="nav"
            disablePadding
            onClick={(e) => {
              console.log(e);
            }}
          >
            <NavList
              item={{
                title: t('navigationhome'),
                path: '/e-learning/landing',
              }}
            />
            <NavList
              item={{
                title: t("navigationmyinfo"),
                path:
                  sessionStorage.getItem('x-at') === null
                    ? '/auth/login-cover'
                    : '/e-commerce/account/personal',
              }}
            />
            <NavList
              item={{
                title: t("navigationmyclass"),
                path:
                  sessionStorage.getItem('x-at') === null
                    ? '/auth/login-cover'
                    : '/e-commerce/account/mylist',
              }}
            />
            <NavList
              item={{
                title: t("navigationmywishlist"),
                path:
                  sessionStorage.getItem('x-at') === null
                    ? '/auth/login-cover'
                    : '/e-commerce/account/wishlist',
              }}
            />
            <NavList
              item={{
                title: t("navigationproduct"),
                path: '/e-learning/courses/1',
              }}
            />
            {/* <NavList item={{
              
              title :"커뮤니티",
              path : "/e-learning/posts"
            }}
            
            /> */}
          </List>

          <Stack spacing={1.5} sx={{ p: 3 }}>
            <Button
              variant="contained"
              color="inherit"
              sx={{ display: 'inline-block' }}
              onClick={() => {
                if (CheckLogin === t("login")) {
                  sessionStorage.setItem('history', window.location.pathname);

                  navigate('/auth/login-cover');
                } else {
                  window.sessionStorage.removeItem('x-at');
                  window.sessionStorage.removeItem('MyIdx');
                  navigate('/e-learning/landing');
                  window.location.reload();
                  // setCheckLogin("Login")
                }
                // mutateSignin({
                //   teacherId :"lydian",
                //   password : "1234"
                // })
              }}
            >
              {CheckLogin}
              {/* {window.sessionStorage.getItem("x-at") !== null? "Logout" : "Login"} */}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              sx={CheckLogin === '로그아웃' || CheckLogin === 'Log out' ? { display: 'none' } : { display: 'inline' }}
              // style={CheckLogin === "Logout" ? {display :"none"}: {background : "rgb(51,0,51)" ,color :"white"}}
              onClick={() => {
                navigate('/auth/register-cover');

                // mutateSignin({
                //   teacherId :"lydian",
                //   password : "1234"
                // })
              }}
            >
                {t("register")}
              {/* {window.sessionStorage.getItem("x-at") !== null? "Logout" : "Login"} */}
            </Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
